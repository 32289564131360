// Base variables
$poppins-font-family:       'Poppins', sans-serif;;
$roboto-font-faimly:     'Roboto', sans-serif;

// brand colors
$base-color:                #9f9f9f;
$light-gray:                #ededed;
$dark-gray:                 #242424;
$black:                     #000000;
$white:                     #FFFFFF;
$orange:                    #fd8c25;
$medium-green:              #1d2d2d;
$green:                     #8b9e9a;
$dark-green:                #075359;

$screen-xxxs:           300px;
$screen-xxs:            400px;
$screen-xxs-min:        400px;
$screen-xsm-min:        577px;
$screen-xs-max:         767px;
$screen-sm-min:         768px;
$screen-sm-max:         992px;
$screen-md-min:         992px;
$screen-md-max:         1199px;
$screen-lg-min:         1200px;
$screen-xlg-min:        1400px;
$screen-xxlg-min:       1601px;
/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  font-size: 14px;
  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: $roboto-font-faimly;
  color: $base-color;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $poppins-font-family;
  color: $medium-green;
  font-weight: 300;
}

h2 {
  font-size: 18px;
  @media (min-width: $screen-sm-min) {
    font-size: 24px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 30px;
  }
  @media (min-width: $screen-xlg-min) {
    font-size: 40px;
  }
}

h4 {
  font-size: 16px;
  @media (min-width: $screen-md-min) {
    font-size: 20px;
  }
}

a {
  transition: all 0.5s ease-out;
  text-decoration: none;
  color: $light-gray;

  &:hover {
    color: $orange;
  }
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

.btn {
  font-size: 14px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }
}

.bs-primary-button {
  transition: all 0.5s ease-out;
  background: linear-gradient(119deg, var(--c1, #FD8C25), var(--c2, #27E5E1) 51%, var(--c1, #FD8C25)) var(--x, 0)/ 200%;
  color: white;
  box-shadow: 0px 10px 10px #00000019;
  border-radius: 12px;
  border: 0;
  padding: 0.5rem 0.8rem;
  min-width: 110px;
  outline: none;
  @media (min-width: $screen-md-min) {
    padding: 0.7rem 1.4rem;
    min-width: 140px;
  }

  &:hover {
    --x: 100%;
  }
}
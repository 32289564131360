@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300;1,400&display=swap');
@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'; 
@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';
@import '~bootstrap/scss/bootstrap';
@import 'variables.scss';
@import 'button.scss';
@import 'helper.scss';
@import 'reset.scss';

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 15px 0;
  z-index: 2;
  transition: all 0.5s ease-out;
  @media (min-width: $screen-sm-min) {
    padding: 30px 30px 30px;
  }
  @media (min-width: $screen-xxlg-min) {
    padding: 40px 140px 40px;
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sitelogo {
    width: 120px;
    height: 40px;
    @media (min-width: $screen-xsm-min) {
      width: 160px;
      height: 50px;
    }
    @media (min-width: $screen-sm-min) {
      width: 190px;
      height: 60px;
    }
    @media (min-width: $screen-xlg-min) {
      width: 210px;
      height: 70px;
    }
  }

  .desktop-menu {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
    }

    ul {
      display: inline-block;
      padding: 0;
      margin: 0;
      
      li {
        display: inline-block;
        margin: 0 70px 0 0;

        &:last-child {
          margin: 0 40px 0 0;
        }

        .link {
          cursor: pointer;
        }
      }
    }
  }

  .navbar {
    padding: 0;
  }

  .navbar-toggler {
    background: $white;
    padding: 5px 10px;
    font-size: 14px;
  }

  .bs-primary-button {
    display: inline-block;
  }

  &.in-sticky {
    background: #075359;
    box-shadow: 0px 15px 10px -15px #111;
    z-index: 999;
    position: fixed;
    top: 0;
    padding-top: 13px;
    padding-bottom: 13px;

    .sitelogo {
      width: 120px;
      height: 40px;
      @media (min-width: $screen-md-min) {
        width: 180px;
        height: 60px;
      }
    }
  }
}

#offcanvasNavbar {
  @extend .green-gradient;
  width: 250px;

  .offcanvas-header {

    .offcanvas-title {
      font-size: 24px;
      color: $light-gray;
    }
    
    .btn-close {
      background-color: $white;
      padding: 5px;
      background-size: 40%;
    }
  }

  .offcanvas-body {

    .navbar-nav {

      .nav-link {
        color: $light-gray;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  .slide-nav-p {
    color: $light-gray;
    padding: 0 0 0 15px;
  }
}

#back-to-top {
  background: $dark-green;
  height: 30px;
  width: 30px;
  right: 10px;
  bottom: 40px;
  padding: 0;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    height: 50px;
    width: 50px;
    right: 40px;
    bottom: 40px;
  }

  @media (min-width: $screen-lg-min) {
    bottom: 70px;
  }

  svg {
    display: inline-block;
    height: 15px;
    width: 15px;
    @media (min-width: $screen-sm-min) {
      height: 22px;
      width: 22px;
    }
  }
}

.banner {
  @extend .green-gradient;
  height: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  @media (min-width: $screen-xsm-min) {
    height: 600px;
  }
  @media (min-width: $screen-sm-min) {
    height: 630px;
    justify-content: center;
  }
  @media (min-width: $screen-md-min) {
    height: 990px;
  }
  @media (min-width: $screen-xlg-min) {
    height: 1100px;
  }
  @media (min-width: $screen-xxlg-min) {
    height: 1200px;
  }

  .banner-caption {
    width: 100%;
    margin: 0;
    padding: 0 15px 0 15px;
    position: relative;
    z-index: 2;
    @media (min-width: $screen-xsm-min) {
      padding: 0 0 0 30px;
      width: 470px;
    }
    @media (min-width: $screen-sm-min) {
      width: 615px;
      padding: 0 0 0 50px;
      margin: -270px 0 0 0px;
    }
    
    h1 {
      color: $white;
      font-size: 21px;
      @media (min-width: $screen-xsm-min) {
        font-size: 24px;
      }
      @media (min-width: $screen-sm-min) {
        font-size: 28px;
      }
      @media (min-width: $screen-md-min) {
        font-size: 43px;
      }
      @media (min-width: $screen-xxlg-min) {
        font-size: 63px;
      }

      span {
        color: $orange;
      }
    }

    h3 {
      color: white;
      font-weight: 300;
    }

    p {
      color: $white;
    }
    
    .button-group {
      display: flex;
      justify-self: self-start;
  
      .btn-link {
        color: $white;
        text-decoration: none;
        font-weight: 300;
  
        &:hover {
          text-decoration: underline;
        }
        
        svg {
          width: 8px;
          display: inline-block;
          margin: 0 0 0 10px;
        }
      }
    }

  }

  .banner-animation {
    margin: 0 0 50px auto;
    display: none;
    @media (min-width: $screen-sm-min) {
      margin: -320px 0 0 100px;
      width: 720px;
      display: block;
    }
    @media (min-width: $screen-xxlg-min) {
      width: 826px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .loadingPlant-animation,
  .cryptoWallet-animation {
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  .loadingPlant-animation {
    width: 170px;
    left: 0;
    @media (min-width: $screen-xsm-min) {
      width: 220px;
      left: 20px;
    }
    @media (min-width: $screen-md-min) {
      width: 230px;
      left: 80px;
    }
    @media (min-width: $screen-lg-min) {
      width: 320px;
      left: 80px;
    }
    @media (min-width: $screen-xxlg-min) {
      width: 420px;
      left: 180px;
    }
  }

  .cryptoWallet-animation {
    width: 140px;
    bottom: -20px;
    left: 60px;
    @media (min-width: $screen-xsm-min) {
      width: 180px;
      bottom: -20px;
      left: 120px;
    }
    @media (min-width: $screen-md-min) {
      bottom: -30px;
      left: 140px;
      width: 220px;
    }
    @media (min-width: $screen-lg-min) {
      width: 330px;
      left: 210px;
      bottom: -60px;
    }
    @media (min-width: $screen-xxlg-min) {
      width: 410px;
      left: 360px;
    }
  }
}

.banner-steps-section {
  position: relative;
  z-index: 1;
  margin: 40px 0;
  @media (min-width: $screen-md-min) {
    margin: -350px 0 160px 0;
  }
  @media (min-width: $screen-lg-min) {
    margin: -390px 0 150px 0;
  }

  .content {
    display: flex;
    color: $base-color;
    margin: 0 0 30px;
    @media (min-width: $screen-sm-min) {
      margin: 0;
    }
    @media (min-width: $screen-md-min) {
      color: white;
    }

    h3 {
      font-size: 18px;
      color: $medium-green;
      @media (min-width: $screen-md-min) {
        color: white;
      }
      @media (min-width: $screen-lg-min) {
        font-size: 28px;
      }
    }

    p {
      font-size: 14px;
      @media (min-width: $screen-lg-min) {
        font-size: 16px;
      }
    }

    span {
      font-size: 30px;
      border-right: 1px solid $orange;
      font-weight: 300;
      color: $orange;
      padding: 0 10px 0 0;
      margin: 0 30px 0 0;
      @media (min-width: $screen-lg-min) {
        font-size: 40px;
      }
    }
  }
}

.about-product {
  margin: 0 0 90px;
  @media (min-width: $screen-sm-min) {
    margin: 290px 0 100px;
  }

  .contact-lotti-animation {
    max-width: 60%;
    margin: 0 auto 20px;
    @media (min-width: $screen-sm-min) {
      max-width: inherit;
      margin: inherit;
    } 
  }

  .about-content {
    text-align: center;
    @media (min-width: $screen-sm-min) {
      text-align: inherit;
    }

    .content {

      h2 {

        span {
          color: $orange;
        }
      }
    }
  }
}

.serv-part-section {

  .content {
    margin: 0 0 30px;
    text-align: center;
    @media (min-width: $screen-sm-min) {
      margin: 0;
      text-align: inherit;
    }
    
    .icon {
      margin: 0 0 15px;
      min-height: 60px;
      display: inline-block;
      @media (min-width: $screen-sm-min) {
        margin: 0 0 30px;
      }

      img {
        max-width: 80%;
      }
    }

    p {
      margin: 0;
      max-width: auto;
      @media (min-width: $screen-sm-min) {
        max-width: 340px;
      }
    }
  }
}

.shape-left-right {
  clip-path: polygon(0px 99.5%, 100.1% 0%, 100% 100%);
  height: 70px;
  width: 100%;
  background: $white;
  position: absolute;
  bottom: -1px;
  @media (min-width: $screen-xsm-min) {
    height: 130px;
  }
  @media (min-width: $screen-sm-min) {
    height: 180px;
  }
}

.shape-right-left {
  clip-path: polygon(0px 0%, 101% 0%, 0.10% 100%);
  height: 70px;
  width: 100%;
  background: $white;
  position: absolute;
  top: -1px;
  @media (min-width: $screen-xsm-min) {
    height: 130px;
  }
  @media (min-width: $screen-sm-min) {
    height: 180px;
  }
}

.secondary-heading {
  background: rgba(253, 140, 37, 0.16);
  color: $orange;
  padding: 0 15px;
  min-width: 130px;
  height: 30px;
  line-height: 30px;
  border-radius: 25px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0 0 15px;
  @media (min-width: $screen-md-min) {
    margin: 0 0 25px;
    min-width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    font-size: 16px;
  }

  svg {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 10px 0 0;
  }
}

.about-us-holder {
  padding: 80px 0 0;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: inherit;
  }
  @media (min-width: $screen-lg-min) {
    padding: 150px 0 0;
  }
  @media (min-width: $screen-xxlg-min) {
    padding: 150px 0 50px;
  }

  .about-animation {
    width: 320px;
    display: inline-block;
    @media (min-width: $screen-xxs-min) {
      width: 350px;
    }
    @media (min-width: $screen-sm-min) {
      width: 100%;
    }
  }

  .about-content {
    display: flex;
    align-items: center;
    height: 100%;

    .content {
      padding: 0;
      @media (min-width: $screen-xlg-min) {
        padding: 0 200px 0 50px;
      }

      h5 {
        margin: 0 0 20px;
      }

      h2 {
        margin: 0 0 10px;
        @media (min-width: $screen-md-min) {
          margin: 0 0 25px;
        }

        br {
          display: none;
          @media (min-width: $screen-xlg-min) {
            display: block;
          }
        }
      }
    }
  }
}

.meet-greet-section {
  // display: none;
  margin: 0;
  padding: 80px 0 0;
  position: relative;
  @media (min-width: $screen-md-min) {
    margin: 50px 0;
    padding: 50px 0;
  }
  @media (min-width: $screen-lg-min) {
    margin: 50px 0;
    padding: 50px 0 100px;
  }

  &:after {
    content: "";
    background: url('../images/line-path-bg.png') no-repeat;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    display: none;
    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }

  .hide-lg {
    display: none;
    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }

  .fly-animation {

    &.style1 {
      width: 160px;
      margin: 50px 0 0;
    }
  }

  .content {
    text-align: center;
    margin: 0 0 30px;
    @media (min-width: $screen-sm-min) {
      text-align: inherit;
      margin: 0;
    }

    h3 {
      font-size: 18px;
      margin: 0 0 10px;
      @media (min-width: $screen-sm-min) {
        margin: 0 0 20px;
        font-size: 24px;
      }
      @media (min-width: $screen-md-min) {
        margin: 0 0 30px;
      }
      @media (min-width: $screen-xlg-min) {
        font-size: 30px;
      }

      span {
        color: $orange;
        font-size: 20px;
        @media (min-width: $screen-sm-min) {
          font-size: 30px;
        }
        @media (min-width: $screen-xlg-min) {
          font-size: 40px;
        }
      }
    }
  }
}

.service-holder {
  padding: 50px 0 20px 0;
  @media (min-width: $screen-sm-min) {
    padding: 70px 0 20px 0;
  }
  @media (min-width: $screen-lg-min) {
    padding: 100px 0 130px 0;
  }

  .service-bg {
    background-image: none;
    background-position: 30px 51px;
    background-size: 87% 90%;
    @media (min-width: $screen-sm-max) {
      background: url('../images/services-bg.png') no-repeat;
    }
    @media (min-width: $screen-md-min) {
      background-position: 20px 50px;
      background-size: 98% 83%;
    }
    @media (min-width: $screen-lg-min) {
      background-position: 30px 33px;
      background-size: 82% 95%;
    }
    @media (min-width: $screen-xlg-min) {
      background-position: 30px 20px;
      background-size: 95% 100%;
    }
  }

  .service-content {
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    margin: 0 0 10px;
    text-align: center;
    @media (min-width: $screen-sm-min) {
      text-align: inherit;
    }
    @media (min-width: $screen-md-min) {
      margin: 0;
    }

    .content {
      margin: auto;
      @media (min-width: $screen-sm-min) {
        margin: 0;
      }
      @media (min-width: $screen-md-min) {
        margin: 0 0 150px;
      }
      @media (min-width: $screen-lg-min) {
        margin: 0 0 115px;
      }
      @media (min-width: $screen-xlg-min) {
        margin: 0 0 100px;
      }
    }

    .small-plant-animation {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 130px;
      display: none;
      @media (min-width: $screen-xxs-min) {
        width: auto;
      }
      @media (min-width: $screen-lg-min) {
        bottom: 216px;
        right: -80px;
        display: block;
      }
      @media (min-width: $screen-xlg-min) {
        right: 0;
      }
    }
  }

  .service-card {
    transition: all 0.5s ease-out;
    background: transparent linear-gradient(132deg, 
    rgba(34, 37, 37, 1) 0%, 
    rgba(21, 54, 54, 1) 60%, 
    rgba(34, 81, 78, 1) 100%) 0% 0% no-repeat;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.19);
    border-radius: 18px;
    padding: 20px;
    margin: 5px 0;
    @media (min-width: $screen-xsm-min) {
      margin: 10px 0;
    }
    @media (min-width: $screen-xlg-min) {
      padding: 25px;
    }

    &:hover {
      box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.65);
      margin-top: 5px;
    }

    .service-icon {
      height: 70px;
      line-height: 70px;
      width: 70px;
      text-align: center;
      border-radius: 50%;
      background: rgba(69, 121, 245, 0.04);
      margin: 0 0 15px;
      @media (min-width: $screen-lg-min) {
        height: 95px;
        line-height: 95px;
        width: 95px;
      }

      svg {
        display: inline-block;
        width: 32px;
        height: 32px;
        @media (min-width: $screen-lg-min) {
          max-width: 100%;
          width: 42px;
          height: 42px;
        }
      }
    }

    h4 {
      font-weight: 400;
      color: $light-gray;
      margin: 0 0 10px;
      line-height: normal;
      @media (min-width: $screen-sm-min) {
        line-height: 28px;
        margin: 0 0 15px;
      }

      br {
        display: none;
        @media (min-width: $screen-md-min) {
          display: block;
        }
      }
    }

    p {
      color: $white;
      font-size: 12px;
      font-weight: 300;
      margin: 0 0 10px;
      min-height: auto;
      @media (min-width: $screen-xsm-min) {
        min-height: 80px;
      }
      @media (min-width: $screen-lg-min) {
        display: block;
        font-size: 14px;
      }
    }
  }
}

.why-block-holder {
  padding: 60px 0 50px;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: inherit;
  }
  @media (min-width: $screen-lg-min) {
    padding: 80px 0 100px;
  }

  .block-animation {
    position: relative;
    width: 300px;
    height: 300px;
    margin: auto;
    @media (min-width: $screen-xxs-min) {
      width: 350px;
      height: 350px;
    }
    @media (min-width: $screen-sm-min) {
      height: 360px;
      width: auto;
      margin: inherit;
    }
    @media (min-width: $screen-md-min) {
      height: 485px;
    }
    @media (min-width: $screen-lg-min) {
      height: 580px;
    }

    .block-img {
      position: absolute;
      top: -10px;
      @media (min-width: $screen-xxs-min) {
        top: -12px;
      }
      @media (min-width: $screen-sm-min) {
        top: -12px;
      }
      @media (min-width: $screen-xlg-min) {
        top: 9px;
      }
    }
    
    > div {
      position: absolute;

      &:last-child {
        margin: -5px 0 0 !important;
        z-index: -1;
        @media (min-width: $screen-xxs-min) {
          margin: -10px 0 0 !important;
        }
      }
    }
  }

  .block-content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    @media (min-width: $screen-sm-min) {
      justify-content: inherit;
    }

    .content {
      padding: 50px 0 0;
      @media (min-width: $screen-sm-min) {
        padding: 0;
      }

      h5 {
        margin: 0 0 20px;
      }

      h2 {
        margin: 0 0 10px;
        @media (min-width: $screen-md-min) {
          margin: 0 0 25px;
        }

        br {
          display: none;
          @media (min-width: $screen-xlg-min) {
            display: block;
          }
        }
      }

      p {

        br {
          display: none;
          @media (min-width: $screen-xlg-min) {
            display: block;
          }
        }
      }
    }
  }

  .block-bullets-detail {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      width: 100%;
      margin: 15px 0;
      @media (min-width: $screen-xsm-min) {
        width: 50%;
        margin: 10px 0;
      }
      @media (min-width: $screen-sm-min) {
        width: 100%;
        margin: 5px 0;
      }
      @media (min-width: $screen-md-min) {
        width: 50%;
        margin: 20px 0;
      }
      @media (min-width: $screen-xlg-min) {
      }

      h4 {
        font-size: 16px;
        @media (min-width: $screen-lg-min) {
          font-size: 20px;
        }
        @media (min-width: $screen-xlg-min) {
          font-size: 24px;
        }

        &:before {
          content: "";
          height: 3px;
          width: 15px;
          border-radius: 3px;
          background: $orange;
          display: inline-block;
          vertical-align: middle;
          margin: -4px 10px 0 0;
        }
      }

      span {
        display: block;
        margin: 2px 0;

        &:first-child {
          color: $dark-gray;
        }

        &:last-child {
          margin: 0 0 0 27px;
          font-size: 12px;
          @media (min-width: $screen-lg-min) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.testimonial-holder {
  @extend .green-gradient;
  margin: 0;
  padding: 150px 0 120px;
  position: relative;
  @media (min-width: $screen-xsm-min) {
    padding: 260px 0 230px;
  }
  @media (min-width: $screen-lg-min) {
    margin: 0 0 100px;
    padding: 380px 0 280px;
  }

  .testimonial-slider-holder {

    .slick-arrow {
      color: $white !important;
      height: 20px;
      width: 20px;
      @media (min-width: $screen-sm-min) {
        height: 40px;
        width: 40px;
      }

      &.slick-prev {
        left: 0;
        @media (min-width: $screen-sm-min) {
          left: -25px;
        }
      }

      &.slick-next {
        right: 0;
        @media (min-width: $screen-sm-min) {
          right: -25px;
        }
      }
    }
  }

  .testimonial-slider {
    text-align: center;

    .testimonial-text {
      padding: 0;
      margin: 0 0 50px;
      @media (min-width: $screen-md-min) {
        padding: 0 160px;
      }
      
      p {
        font-size: 20px;
        color: $white;
        font-weight: 300;
        margin: 0;
        padding: 10px 0 0;
        @media (min-width: $screen-md-min) {
          font-size: 30px;
        }

        svg {
          display: inline-block;
          margin: -50px 10px 0 0px;
        }
      }
    }

    .user-profile {
      display: inline-block;
      text-align: center;

      .profile-img {
        background: url('../images/profile-bg.png') no-repeat;
        background-position: center center;
        height: 122px;
        width: 122px;
        padding: 10px;
        display: inline-block;
        margin: 0 0 15px;
      }

      h5 {
        font-weight: 300;
        color: $white;
        font-size: 16px;
        margin: 0;

        span {
          display: block;
        }
      }
    }
  }
}

.projects-and-clients {
  padding: 60px 0 0;
  @media (min-width: $screen-lg-min) {
    padding: 100px 0 0;
  }

  .successful-projects {
    padding: 0 0 100px;
    @media (min-width: $screen-lg-min) {
      padding: 0 0 150px;
    }
    
    .main-head {
      text-align: center;
      padding: 0 0 30px;
      @media (min-width: $screen-lg-min) {
        padding: 0 0 60px;
      }
  
      h2 {
        margin: 0 0 20px;
        line-height: normal;
        @media (min-width: $screen-sm-min) {
          line-height: 46px;
        }
        @media (min-width: $screen-lg-min) {
          margin: 0 0 50px;
        }
        @media (min-width: $screen-xlg-min) {
          line-height: 66px;
        }
      }
  
      p {

        br {
          display: none;
          @media (min-width: $screen-sm-min) {
            display: block;
          }
        }
      }
    }
  
    .acounter {
      text-align: center;

      .inline-bolck {
        display: inline-block;
      }
  
      .col {
        text-align: center;
      }
  
      .acounter-strong {
        display: inline-block;
        font-size: 30px;
        font-family: $poppins-font-family;
        color: $orange;
        font-weight: 500;
        @media (min-width: $screen-xsm-min) {
          font-size: 40px;
        }
        @media (min-width: $screen-md-min) {
          font-size: 60px;
        }
        @media (min-width: $screen-xlg-min) {
          font-size: 80px;
        }
      }

      .counter-numbers {
        height: 40px;
        @media (min-width: $screen-xsm-min) {
          height: 60px;
        }
        @media (min-width: $screen-md-min) {
          height: 90px;
        }
        @media (min-width: $screen-xlg-min) {
          height: 120px;
        }
      }

      .plus-ac {
        @extend .acounter-strong;
        font-style: normal;
      }
  
      h4 {
        display: block;
        color: $dark-gray;
      }
    }
  }

  .our-clients {
    text-align: center;
    padding: 0 0 100px;
    @media (min-width: $screen-lg-min) {
      padding: 0 0 150px;
    }

    h2 {
      margin: 0 0 30px;
      display: inline-block;
      @media (min-width: $screen-lg-min) {
        margin: 0 0 60px;
      }
    }
  }
}

.contact-us {
  background: transparent linear-gradient(100deg, #E3730D 0%, #27E5E1 100%) 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #0000000F;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 0 -80px;
  position: relative;
  @media (min-width: $screen-md-min) {
    padding: 30px 60px;
  }
  @media (min-width: $screen-lg-min) {
    margin: 0 148px -80px;
  }

  .content {
    margin: 0 0 20px;
    @media (min-width: $screen-md-min) {
      margin: 0 30px 0 0;
    }

    h2 {
      color: $white;
      margin: 0 0 10px;
      @media (min-width: $screen-lg-min) {
        margin: 0 0 20px;
      }
    }

    p {
      margin: 0;
      color: $white;
    }
  }

  .search-bar {
    position: relative;;
  
    input {
      background: $white;
      border: 0;
      border-radius: 18px;
      padding: 0 120px 0 20px;
      width: 100%;
      box-sizing: border-box;
      font-size: 16px;
      height: 50px;
      font-weight: 300;
      @media (min-width: $screen-md-min) {
        padding: 0 160px 0 20px;
        height: 60px;
      }
  
      &:focus {
        outline: none;
      }
  
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #7C7C7C;
        opacity: 1; /* Firefox */
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #7C7C7C;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #7C7C7C;
      }
    }
  
    .btn {
      position: absolute;
      top: 7px;
      right: 7px;
      min-width: 110px;
    }
  }

  .small-plant-animation {
    position: absolute;
    width: 190px;
    bottom: 50px;
    display: none;
    @media (min-width: $screen-lg-min) {
      display: block;
    }
    @media (min-width: $screen-xxlg-min) {
      width: 270px;
      bottom: 40px;
    }

    &.left {
      right: 100%;
      width: 200px;
    }

    &.right {
      left: 100%;
      width: 220px;
      margin: 0 0 -10px -30px;
      @media (min-width: $screen-xlg-min) {
        width: 250px;
        margin: 0 0 -10px -20px;
      }
    }
  }
}

.mid-text {
  text-align: center;
  margin: 30px 0 0;

  h1 {
    margin: 0;
    text-transform: capitalize;
  }
}

footer {
  @extend .green-gradient;
  padding: 100px 0 0;
  @media (min-width: $screen-md-min) {
    padding: 170px 0 0;
  }

  p {
    color: $light-gray;
    font-weight: 300;
  }

  .footer-columns {
    padding: 0 0 0;
    @media (min-width: $screen-md-min) {
      padding: 0 0 100px;
    }

    .custom-set {
      margin: 15px 0;
      @media (min-width: $screen-lg-min) {
        margin: 0;
      }
    }
  
    .social-links-list {
      padding: 0;
      margin: 0;
      list-style: none;
  
      li {
        display: inline-block;
        margin: 0 0 0 10px;
  
        &:first-child {
          margin: 0;
        }
  
        a {
          font-size: 20px;
        }
      }
    }
  
    .logo-column {
      padding: 0;
      @media (min-width: $screen-md-min) {
        padding: 0 50px 0 0;
      }
  
      .footer-logo {
        margin: 0 0 10px;
        display: block;
        @media (min-width: $screen-md-min) {
          margin: 0 0 35px;
        }
  
        svg {
          width: 110px;
          height: 40px;
          @media (min-width: $screen-md-min) {
            width: 200px;
            height: 70px;
          }
        }
      }
  
      p {
        margin: 0 0 15px;
        @media (min-width: $screen-md-min) {
          margin: 0 0 30px;
        }

        br {
          display: none;
          @media (min-width: $screen-md-min) {
            display: block;
          }
        }
      }
    }
  
    .link-column {
  
      h5 {
        font-size: 16px;
        color: $light-gray;
        margin: 0 0 10px;
        font-weight: 500;
        @media (min-width: $screen-sm-min) {
          font-size: 18px;
        }
        @media (min-width: $screen-md-min) {
          margin: 20px 0 20px;
        }
        @media (min-width: $screen-lg-min) {
          margin: 20px 0 30px;
        }
      }
  
      .link-list {
        padding: 0;
        margin: 0;
        list-style: none;
  
        li {
          display: block;
          margin: 0 0 8px;
          @media (min-width: $screen-md-min) {
            margin: 0 0 15px;
          }
          
  
          &:last-child {
            margin: 0;
          }
          
          a {
            font-weight: 300;
          }
        }
      }
    }
  }
  
  .sub-footer {
    padding: 15px 0;
    border-top: 1px solid rgba(255,255,255,0.1);
    display: flex;
    justify-content: space-between;
    @media (min-width: $screen-md-min) {
      padding: 30px 0;
    }

    p {
      margin: 0;
    }
  }
}

.contact-page {
  padding: 190px 0 100px;

  .mian-head {
    margin: 0 0 80px;

    h1 {
      margin: 0;
    }
  }
}

.contact-form {
  padding: 0;
  @media (min-width: $screen-xlg-min) {
    padding: 0 100px 0 0;
  }
  
  .form-control {
    border: 0;
    border-bottom: 1px solid $base-color;
    border-radius: 0;
    font-weight: 400;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $base-color;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $base-color;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $base-color;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.breadcrumb {
  margin: 0 0 5px;
  
  .breadcrumb-item {
    
    a {
      color: $dark-gray;
    }

    &.active {
      color: $orange;
    }
  }
}

.inner-page-header {
  height: 50px;
  @media (min-width: $screen-xsm-min) {
    height: 70px;
  }
  @media (min-width: $screen-sm-min) {
    height: 80px;
  }
  
  header {
    background: #075359;
    box-shadow: 0px 15px 10px -15px #111;
    z-index: 999;
    position: fixed;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.inner-page-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 178px;
  flex-direction: column;
  background: #F6F6F6;
  margin: 0 0 45px;
  @media (min-width: $screen-xsm-min) {
    height: 218px;
    margin: 0 0 65px;
  }

  h1 {
    margin: 0;
  }
}

.blog-list-page {
  padding: 0 0 50px;

  .filter-list-holder {
    margin: 0 0 40px;
    @media (min-width: $screen-sm-min) {
      margin: 0 0 80px;
    }

    .filter-list {
      margin: 0 -10px;
      display: flex;
      padding: 0 30px;

      .slick-slide {

        .slick-current {
          
          a {
            border-color: $orange;
            color: $orange;
          }
        }
      }
      
      a {
        min-width: 110px;
        height: 40px;
        font-size: 14px;
        display: flex;
        color: #818180;
        border: 1px solid #818180;
        border-radius: 4px;
        margin: 0 5px;
        align-items: center;
        justify-content: center;
        @media (min-width: $screen-md-min) {
          min-width: 150px;
          height: 65px;
          font-size: 18px;
          margin: 0 10px;
        }
      }

      .slick-arrow {
        color: #707070;
        padding: 0 5px;
        height: 125%;
        background-size: cover;
        background-position: center;
        background-image: url(../../src/images/blur-bg.png);
        z-index: 1;

        &.slick-disabled {
          opacity: 0.3;
        }

        &.slick-prev {
          left: 20px;
        }

        &.slick-next {
          right: 20px;
        }
      }
    }
  }

  .md-blog-post {
    margin: 0 0 45px;

    figure {
      margin: 0 0 15px;

      img {
        max-width: 100%;
      }
    }

    .detail {
      
      h4 {
        margin: 0;
      }
    }
  }

  .featured-list-title {
    padding: 30px 0 0;
    margin: 0 0 30px;
    border-top: 1px solid rgba(0,0,0,0.2);
    @media (min-width: $screen-sm-min) {
      border: 0;
      padding: 0;
    }
  }

  .list-post-style {
    display: block;
    align-items: flex-start;
    margin: 0 0 30px;
    @media (min-width: $screen-md-min) {
      display: flex;
    }

    &:last-child {
      margin: 0;
    }

    figure {
      width: inherit;
      height: 200px;
      margin: 0 0 15px;
      @media (min-width: $screen-sm-min) {
        width: 206px;
        height: 174px;
      }
      @media (min-width: $screen-md-min) {
        margin: 0 25px 0 0;
      }

      img {
        max-width: inherit;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (min-width: $screen-sm-min) {
          width: inherit;
          height: inherit;
        }
      }
    }

    .detail {

      h5 {
        margin: 0 0 10px;
        @media (min-width: $screen-md-min) {
          margin: 0 0 15px;
        }
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      p {
        color: #575756;
        margin: 0 0 10px;
        @media (min-width: $screen-md-min) {
          margin: 0 0 15px;
        }
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      a.link-btn {
        color: $orange;
        margin: 0;

        svg {
          display: inline-block;
        }
      }
    }
  }
}

.blog-detail {
  padding: 50px 0;
  @media (min-width: $screen-sm-min) {
    padding: 80px 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #242C42;
  }

  h3 {
    font-size: 18px;
    @media (min-width: $screen-sm-min) {
      font-size: 24px;
    }
    @media (min-width: $screen-md-min) {
      font-size: 30px;
      line-height: 46px;
    }
    @media (min-width: $screen-xlg-min) {
      font-size: 30px;
    }
  }

  p {
    a {
      color: $orange;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .page-head {
    margin: 0 0 40px;
    
    h2 {
      @media (min-width: $screen-xlg-min) {
        font-size: 35px;
      }
    }

    .share-list {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: inline-block;
        color: #9F9F9F;

        &::after {
          content: "|";
           margin: 0 10px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        a {
          color: #9F9F9F;
        }

        svg {
          display: inline-block;
          margin: 0 7px 0 0;
        }
      }
    }
  }

  .social-icons {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 0 0 30px;

      &:first-child {
        margin: 0;
      }

      a {
        font-size: 25px;
        color: #818180;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  .post-grid {

    figure {
      
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        @media (min-width: $screen-sm-min) {
          width: inherit;
          height: inherit;
          object-fit: none;
        }
      }
    }

    .detail {

      h5 {
        margin: 0;
        line-height: 20px;

        a {
          font-size: 16px;
          color: #242C42;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .comments-list {
    margin: 40px 0 0;
    padding: 40px 0 0;
    border-top: 1px solid #EDEDED;
    @media (min-width: $screen-sm-min) {
      margin: 90px 0 0;
      padding: 90px 0 0;
    }

    .comment {
      display: flex;
      margin: 0 0 15px;
      padding: 0 0 15px;
      border-bottom: 1px solid #EDEDED;
      @media (min-width: $screen-md-min) {
        margin: 0 0 30px;
        padding: 0 0 30px;
      }

      &:last-child {
        border-bottom: 0;
        margin: 0;
        padding: 0;
      }

      figure {
        margin: 0;

        img {
          max-width: inherit;
          width: 70px;
          @media (min-width: $screen-md-min) {
            width: 120px;
          }
        }
      }

      .detail {
        padding: 0 0 0 15px;
        @media (min-width: $screen-md-min) {
          padding: 0 0 0 30px;
        }

        .user-name {
          margin: 0 0 5px;
        }

        .date {
          margin: 0 0 10px;
          display: block;
          font-size: 14px;
          @media (min-width: $screen-md-min) {
            margin: 0 0 20px;
          }
        }

        p {
          margin: 0 0 20px;
          @media (min-width: $screen-md-min) {
            margin: 0 0 30px;
          }
        }

        .btn-link {
          color: $orange;
          text-transform: uppercase;
          text-decoration: none;
          float: right;
        }
      }

      &.replay {
        padding: 0 0 15px 80px;
        @media (min-width: $screen-md-min) {
          padding: 0 0 30px 150px;
        }
      }
    }
  }
}

.page-loader {
  background: rgba(255, 255, 255, 0.98);
  position: fixed;
  z-index: 9999;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  &.true {
    display: flex;
  }

  .loader{
    display:grid;
    justify-items: center;
    
    .circle {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      grid-area: 1/1; 
      
      &.green{
        background-color:#075359;;
        animation:move-right 2s linear infinite
      }
      
      &.orange{
         background-color: $orange;
         animation:move-left 2s linear infinite
      }
    }
  }
  
  @keyframes move-right{
    0%, 100%{
      margin-right:0
    }
    
      25%{
      margin-right:100px;
        
    }
    50%{
      z-index:2
    }
    
    75%{
      margin-right:-100px;
    }
  }
  
  @keyframes move-left{
    0%, 100%{
      margin-left:0
    }
    
    25%{
      margin-left:100px;
    }
    
    75%{
      margin-left:-100px;
    }
  }  
}


.pagination-holder {
  text-align: center;
  .pagination {
    justify-content: center;
    .page-item {
      .page-link {
        color: $base-color;
      }
    }
  }
}
